/* External dependencies */
import { createStore, combineReducers, Store, Action } from 'redux';
import { persistStore, persistReducer, } from 'redux-persist';
import Storage from 'redux-persist/lib/storage';


/* Internal dependencies */
import currentUserReducer, { CurrentUserState, INITIAL_STATE as CURRENT_USER_INITIAL_STATE } from './ducks/currentUser';
import playerReducer, { PlayerState, INITIAL_STATE as PLAYER_INITIAL_STATE } from './ducks/player';
import eventsReducer, { EventsState, INITIAL_STATE as EVENTS_INITIAL_STATE } from './ducks/events';
import postsReducer, { PostsState, INITIAL_STATE as POSTS_INITIAL_STATE } from './ducks/posts';
import likesReducer, { LikesState, INITIAL_STATE as LIKES_INITIAL_STATE } from './ducks/likes';
import notificationsReducer, {
  NotificationsState,
  INITIAL_STATE as NOTIFICATIONS_INITIAL_STATE,
} from './ducks/notifications';
import artistsReducer, { ArtistsState, INITIAL_STATE as ARTISTS_INITIAL_STATE } from './ducks/artists';
import activitiesReducer, {
  ActivitiesState,
  INITIAL_STATE as ACTIVITIES_INITIAL_STATE,
  friendsActivitiesReducer,
  FriendsActivitiesState,
  FRIENDS_INITIAL_STATE as FRIENDS_ACTIVITIES_INITIAL_STATE,
} from './ducks/activities';
import storiesReducer, {
  StoriesState,
  INITIAL_STATE as STORIES_INITIAL_STATE,
  friendsStoriesReducer,
  FriendsStoriesState,
  FRIENDS_INITIAL_STATE as FRIENDS_STORIES_INITIAL_STATE,
} from './ducks/stories';
import communityFeedItemsReducer, { PAGINATOR_ID as COMMUNITY_FEED_PAGINATOR_ID } from './ducks/communityFeedItems';
import { ItemsState, INITIAL_STATE as PAGINATOR_INITIAL_STATE } from './ducks/paginator';
import commentsReducer, { CommentsState, INITIAL_STATE as COMMENTS_INITIAL_STATE } from './ducks/comments';
import usersReducer, { UsersState, INITIAL_STATE as USERS_INITIAL_STATE } from './ducks/users';
import communitiesReducer, { CommunitiesState, INITIAL_STATE as COMMUNITIES_INITIAL_STATE } from './ducks/communities';
import browseReducer, { BrowseState, INITIAL_STATE as BROWSE_INITIAL_STATE } from './ducks/browse';
import transforms from './transforms';

enum RootActionType {
  LOGOUT = 'reducer/root/LOGOUT',
}

export type ApplicationState = {
  currentUser: CurrentUserState;
  events: EventsState;
  artists: ArtistsState;
  communities: CommunitiesState;
  users: UsersState;
  stories: StoriesState;
  friendsStories: FriendsStoriesState;
  activities: ActivitiesState;
  friendsActivities: FriendsActivitiesState;
  posts: PostsState;
  comments: CommentsState;
  likes: LikesState;
  browse: BrowseState;
  [COMMUNITY_FEED_PAGINATOR_ID]: ItemsState;
  notifications: NotificationsState;
  player: PlayerState;
};

/** Combined set of all reducers. */
export const reducers = combineReducers<ApplicationState>({
  currentUser: currentUserReducer,
  events: eventsReducer,
  artists: artistsReducer,
  communities: communitiesReducer,
  users: usersReducer,
  stories: storiesReducer,
  friendsStories: friendsStoriesReducer,
  activities: activitiesReducer,
  friendsActivities: friendsActivitiesReducer,
  posts: postsReducer,
  comments: commentsReducer,
  likes: likesReducer,
  browse: browseReducer,
  [COMMUNITY_FEED_PAGINATOR_ID]: communityFeedItemsReducer,
  notifications: notificationsReducer,
  player: playerReducer,
});

const INITIAL_STATE: ApplicationState = {
  currentUser: CURRENT_USER_INITIAL_STATE,
  player: PLAYER_INITIAL_STATE,
  artists: ARTISTS_INITIAL_STATE,
  events: EVENTS_INITIAL_STATE,
  likes: LIKES_INITIAL_STATE,
  browse: BROWSE_INITIAL_STATE,
  notifications: NOTIFICATIONS_INITIAL_STATE,
  activities: ACTIVITIES_INITIAL_STATE,
  friendsActivities: FRIENDS_ACTIVITIES_INITIAL_STATE,
  stories: STORIES_INITIAL_STATE,
  friendsStories: FRIENDS_STORIES_INITIAL_STATE,
  posts: POSTS_INITIAL_STATE,
  comments: COMMENTS_INITIAL_STATE,
  users: USERS_INITIAL_STATE,
  communities: COMMUNITIES_INITIAL_STATE,
  [COMMUNITY_FEED_PAGINATOR_ID]: PAGINATOR_INITIAL_STATE,
};

const rootReducer: any = (state: ApplicationState, action: Action) => {
  if (action.type === RootActionType.LOGOUT) {
    return reducers(INITIAL_STATE, action);
  }

  return reducers(state, action);
};

const persistConfig: any = {
  key: 'root',
  storage: Storage,
  whitelist: [
    'forYouFeedItems',
    // 'friendsFeedItems',
    // 'primaryChats',
    // 'activityChats',
    // 'chatRequestChats',
    // 'chats',
    // 'posts',
    // 'polls',
    // 'pollOptions',
    'events',
    'communities',
    // 'browse',
    'currentUser',
    // 'notifications',
    // 'permissions',
    'player',
  ],
  transforms,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store: Store<ApplicationState> = createStore(persistedReducer);
export const persistor = persistStore(store);

// const store: Store = createStore(
//   reducers,
//   typeof window !== 'undefined' &&
//     (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
//     (window as any).__REDUX_DEVTOOLS_EXTENSION__()
// );

export default store;
