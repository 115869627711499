/* External dependencies */
import React from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

/* Internal dependencies */
import { addMemberForLaunchWaitlist } from 'src/api/waitlists';
import Button from 'src/button/Button';
import { addNotification } from 'src/store/ducks/notifications';
import { Member } from 'src/types/Member';
import { Notification } from 'src/types/Notification';
import SubscribeModal from './SubscribeModal';
import { logEvent } from '@firebase/analytics';
import { analytics } from 'src/firebase';

type OwnProps = {
  showLabel?: boolean;
  buttonText?: React.ReactNode;
  buttonVariant?: string;
  onShow?: () => void;
};

type DispatchProps = {
  addNotification(notification: Omit<Notification, 'id'>): void;
};

type Props = OwnProps & DispatchProps;

type State = {
  email: string | undefined;
  member: Member | undefined;
  loading: boolean;
  open: boolean;
};

class Subscribe extends React.Component<Props, State> {
  state = { email: undefined, member: undefined, loading: false, open: false };

  constructor(props: Props) {
    super(props);
    this.onSubscribe = this.onSubscribe.bind(this);
  }

  componentDidMount() {
    window.document.addEventListener('keyup', this.handleEnterPress);
  }

  componentWillUnmount() {
    window.document.removeEventListener('keyup', this.handleEnterPress);
  }

  handleEnterPress = (e: KeyboardEvent) => {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();

    if (e.code === 'Enter') {
      const input = document.getElementById('mce-EMAIL');
      if (input === document.activeElement) {
        this.onSubscribe();
      }
    }
  };

  handleEmailChange = (e: any) => {
    this.setState({ email: e.target.value });
  };

  showModal = () => {
    this.setState({ open: true });
    logEvent(analytics, 'waitlist_openModal', this.state);
  };

  hideModal = () => {
    this.setState({ email: undefined, open: false });
    logEvent(analytics, 'waitlist_closeModal', this.state);
  };

  onSubscribe = async () => {
    const { addNotification } = this.props;
    const { email } = this.state;

    if (email) {
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const valid = email && emailRegex.test(email!);

      if (!valid) {
        addNotification({
          title: 'Invalid Email Address',
          message: 'The email address you entered is invalid. Please enter a valid email address.',
          variant: 'danger',
        });
        return;
      }

      try {
        this.setState({ loading: true });
        const urlParams = new URLSearchParams(window.location.search);
        const referralId = urlParams.get('ref_id') || undefined;
        const member = await addMemberForLaunchWaitlist({
          email: email!,
          referralId,
        });
        console.log('member', member);
        this.setState({ member });
        this.showModal();
      } catch (e) {
        console.log('error with user subscription', e);
        addNotification({
          title: 'Already Subscribed',
          message: 'This email address is already subscribed to Beatmatch.',
          variant: 'danger',
        });
      } finally {
        this.setState({ loading: false });
      }
    } else {
      addNotification({
        title: 'No Email',
        message: 'You must enter an email to subscribe. Please try again.',
        variant: 'danger',
      });
    }
  };

  render() {
    const { showLabel = true, buttonText = 'Join the club', buttonVariant = 'primary', onShow } = this.props;
    const { email, member, loading, open } = this.state;

    return (
      <div id="mc_embed_signup">
        <Form id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" noValidate>
          <div className="d-flex flex-column align-items-center" id="mc_embed_signup_scroll">
            {showLabel && (
              <label className="form-label text-white text-center" htmlFor="mce-EMAIL">
                Join our music community!
              </label>
            )}
            <div className="input-group mb-1">
              <Form.Control
                onInput={this.handleEmailChange}
                className="form-control"
                type="email"
                value={email || ''}
                name="Email"
                id="mce-EMAIL"
                aria-required="true"
                placeholder="Enter email address"
                aria-label="Enter email address"
                aria-describedby="mc-embedded-subscribe"
                style={{ borderTopLeftRadius: 20, borderBottomLeftRadius: 20, minHeight: 50 }}
                required
              />
              <div className="input-group-append">
                <div style={{ position: 'absolute', left: -5000 }} aria-hidden="true">
                  <input type="text" name="b_1328aa7a8f414acc4aaad2634_8a7f87c317" tabIndex={-1} value="" />
                </div>
                <Button
                  loading={loading}
                  type="button"
                  onClick={this.onSubscribe}
                  variant={buttonVariant}
                  id="mc-embedded-subscribe"
                  style={{ paddingTop: 0, paddingBottom: 0, fontWeight: 700, borderTopRightRadius: 20, borderBottomRightRadius: 20, minHeight: 50 }}
                >
                  {buttonText}
                </Button>
              </div>
            </div>
          </div>
        </Form>
        {open && <SubscribeModal initialEmail={email} member={member} show={open} onShow={onShow} onHide={this.hideModal} />}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addNotification: (notification: Omit<Notification, 'id'>) => {
    dispatch(addNotification(notification));
  },
});

export default connect(null, mapDispatchToProps)(Subscribe);
